<script setup lang="ts">
  import useCpBem from '~/composables/useCpBem';
  import CpText from '~/components/cp/CpText/CpText.vue';
  import CpNuxtLink from '~/components/cp/CpNuxtLink/CpNuxtLink.vue';

  const props = withDefaults(defineProps<{
    id: string,
    image: string,
    link: string
    thin?: boolean,
    name?: string,
    showName?: boolean,
    fullWidth?: boolean
  }>(), {
    showName: true,
    fullWidth: false,
  });

  const {b, em, e} = useCpBem('cp-card-brand');
</script>

<template>
    <CpNuxtLink :class="b" data-test="cp-card-brand" page-name="catalog" :link="link">
        <div :class="em('card', {'thin':thin!, static: !showName })">
            <v-img :class="em('card-img', {'thin': thin!, static: !showName, 'full-width': fullWidth })" :src="image" :alt="name ? name : image" />
            <CpText
                v-if="props.name && props.showName"
                variant="body-regular"
                type="primary"
                :class="e('card-name')"
                data-testid="card-brand-name"
            >
                {{ name }}
            </CpText>
        </div>
    </CpNuxtLink>
</template>

<style scoped lang="scss">
    .cp-card-brand {
      text-decoration: none;

      &__card {
        background-color: var(--background-color);
        border: var(--border-card);
        border-radius: $cp-border-radius;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $cp-gutter-small;
        padding: var(--padding-card);
        text-decoration: none;
        &--thin {
          padding: $cp-gutter-small $cp-gutter-xlarge;
        }
        &--static {
          width: 99px;
        }
      }
      &__card-img {
        height: var(--height-image);
        width: calc(100% - $cp-gutter);

        &--thin {
          width: 72px;
          margin: 0;
        }

        &--static {
          width: 67px;
        }

        &--full-width {
          width: 100%;
        }
      }
      &__card-name {
        text-align: center;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
</style>
